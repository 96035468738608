import React from "react"
import { Link, graphql, PageProps } from "gatsby";

import Layout from "../components/layout"
import Seo from "../components/seo"
import { kebabCase } from "lodash";
import { h3, small } from "../base/typography.css";

type BlogQueryPageProps = {
  site: {
    siteMetadata: {
      title: string;
    }
  };
  postsRemark: {
    edges: {
      node: {
        excerpt: string;
        fields: {
          slug: string;
        };
        frontmatter: {
          date: string;
          title: string;
          description: string;
        }
      };
    }[];
  };
  tags: {
    group: {
      fieldValue: string;
      totalCount: number;
    }[]
  }
}

type BlogIndexProps = PageProps<BlogQueryPageProps>;

const BlogIndex = ({ data, location }: BlogIndexProps) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.postsRemark.edges
  const tags = data.tags.group.sort((tagA, tagB) => {
    if (tagA.totalCount > tagB.totalCount) {
      return -1;
    }
    return tagA.totalCount < tagB.totalCount ? 1 : 0;
  })

  const sidebar = (<ul>
    {tags.map(({fieldValue, totalCount}) => (
      <li key={fieldValue}>
        <Link to={`/tags/${kebabCase(fieldValue)}`}>
          {fieldValue} ({totalCount})
        </Link>
      </li>
    ))}
  </ul>);

  return (
    <Layout location={location} title={siteTitle} sidebar={sidebar}>
      <Seo title="All posts" />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug}>
            <header>
              <h3 className={h3}>
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <time className={small}>{node.frontmatter.date}</time>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    postsRemark: allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
    tags: allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue,
        totalCount
      }
    }
  }
`
